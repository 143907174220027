.sect-title {
  color: #343433;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 560px;
  padding: 25px 106px;
  justify-content: center;
  align-items: center;
  /* flex-shrink: 0; */
  border-radius: 24px;
  border: 1px solid #dedee9;
  margin: 0 1rem;
}
.input-wrapper-sm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 560px;
  justify-content: center;
  align-items: center;
  /* flex-shrink: 0; */
  border-radius: 24px;
  border: 1px solid #dedee9;
  margin: 0 1rem;
}
.cust-grey-1 {
  color: #5e6166;
}
.cust-grey-2 {
  color: #dddfed;
}
.answer-wrapper {
  border-radius: 24px;
  max-width: 560px;
  border: 1px solid rgba(32, 224, 131, 0.2);
  background: #eef7f6;
  display: flex;
  padding: 50px 12px;
  /* overflow-x: auto; */
  justify-content: center;
  align-items: center;
  color: #20e083;
  margin: 0 1rem;
}
.answer-wrapper-sm {
  border-radius: 12px;
  max-width: 560px;
  border: 1px solid rgba(32, 224, 131, 0.2);
  background: #eef7f6;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #20e083;
  margin: 0 1rem;
}

.btn {
  border-radius: 12px;
  border: 1px solid #d1dee5;
  display: flex;
  padding: 11px 15px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
.btn-active{
    color: #FFFFFF;
    background-color: #171717;
}
.btn-inactive{
    color: #171717;
}
