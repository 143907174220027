@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@layer base {
  h1 {
    @apply text-5xl;
    @apply font-bold;
  }
  h2 {
    @apply text-2xl;
    @apply font-bold;
    @apply pt-16;
    /* @apply pb-6; */
  }
  h3 {
    @apply text-lg;
    @apply font-bold;
    @apply pt-5;
  }
  p {
    @apply pt-2;
    @apply pb-2;
  }
}

@media (min-width: 768px) {
  .orient-partner-image {
    width: 10.5rem;
  }
  .etica-partner-image {
    width: 7.5rem;
  }
}

.blog-item-title {
  height: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-item-description {
  height: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.font-site {
  font-family: "Inter", sans-serif;
}
.nav-item-active {
  border-radius: 8px;
  border: 0.5px solid rgba(94, 97, 102, 0);
  background: rgba(94, 97, 102, 0.1);
}
.font-icon{
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}
