.btn-primary {
  border-radius: 10px;
  border: 1px solid #41e595;
  background: #20e083;
  padding: 12px 28px;
  color: #052214;
  cursor: pointer;
}
.text-muted {
  color: #5e6166;
}
.text-extra-muted {
  color: #5e6166;
}
.landing-text {
  color: #121212;
  font-weight: 900;
  text-transform: uppercase;
}
.landing-subtitle {
  color: #232426;
  font-weight: 900;
  text-transform: uppercase;
}
.text-custom-80 {
  font-size: 80px !important;
  line-height: normal;
  letter-spacing: -1.6px;
}
.text-custom-240 {
  font-size: 240px !important;
  line-height: normal;
  letter-spacing: -4.8px;
}
.landing-text3 {
  color: #232426;
}
.landing-card {
  border-radius: 24.087px;
  border: 0.803px solid #d8d8d8;
}
.card-title {
  color: #232e3c;
  font-weight: 700;
}
.card-content {
  color: rgba(71, 70, 69, 0.7);
  font-weight: 400;
}
.card-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
}
.card-wrapper-sm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.text-custom-green{
  color: #20E083;
}