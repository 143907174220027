.about-landing {
  position: relative;
  margin-right: "100px";
  background-image: url("../../assets/img/about.png");
  background-position: 75% 56%;
  background-repeat: no-repeat;
  background-size: cover;
}
.about-landing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: none;
}

.table-border {
  border-top: 1px solid #e4e6ec;
  border-bottom: 1px solid #e4e6ec;
}
.table-border-top {
  border-top: 1px solid #e4e6ec;
}
