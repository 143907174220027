.slidder-wrapper {
  margin: 0 1rem;
}
.fund-card {
  max-width: 96%;
  height: 100%;
  margin: auto;
  border-radius: 16px;
  background: #111;
  box-shadow: 0px -8px 30px -4px rgba(32, 224, 131, 0.12) inset;
}
.fund-card-lg {
  border-radius: 16px;
  background: #111;
  box-shadow: 0px -8px 30px -4px rgba(32, 224, 131, 0.12) inset;
  width: 100%;
  margin: auto 1rem;
  height: 100%;
}
.fund-title {
  color: var(--White, #fff);
}
.fund-subtitle {
  color: #d0d5dd;
}
.fund-subtitle-sm {
  font-size: 9.45px;
}
.testimonial-video {
  border-top-left-radius: 60.78px;
  border-top-right-radius: 60.78px;
}
.testimonial-text {
  color: #121212;
  font-weight: 900;
  text-transform: uppercase;
}
.testimonial-subtitle {
  color: #232426;
  font-weight: 900;
  text-transform: uppercase;
}
.rotate-180 {
  transform: rotate(180deg);
}
.help-text {
  color: #232426;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  letter-spacing: -1.08px;
}
.border-color {
  border-color: #232426;
}
.line {
  background: var(--grey-25, #fcfcfd);
  height: 1px;
  align-self: stretch;
  margin-bottom: 53px;
  margin-top: 20px;
}
.check {
  width: 29.679px;
  height: 28.751px;
  flex-shrink: 0;
}
.check-sm {
  width: 13.164px;
  height: 12.753px;
  flex-shrink: 0;
}
.list {
  color: var(--grey-25, #fcfcfd);
}
.list-sm {
  font-size: 9.45px;
}
.btn {
  border-radius: 9.45px;
  background: var(--White, #fff);
  padding: 9.45px 18.901px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.bg-wrapper{
  background-image: url("../../assets/img/potential.png");
  background-position: 50% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
}
.bg-wrapper-sm{
  background-image: url("../../assets/img/potential.png");
  background-position: 20% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;
}
.custom-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 40px;
  height: 40px;
  background: transparent;
}

.prev-arrow {
  left: -55px; 
}

.next-arrow {
  right: -55px; 
}

.custom-arrow:hover {
  opacity: 0.8;
}

.custom-arrow-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 42px;
  height: 42px;
  background: transparent;
}

.prev-arrow-sm {
  left: -45px; 
}

.next-arrow-sm {
  right: -45px; 
}
/* Small - Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
  .hidden-sm {
    display: none;
  }
  .hidden-lg {
    display: block;
  }
}

/* Extra large devices (large laptops and desktops, 769px and up) */
@media only screen and (min-width: 769px) {
  .hidden-lg {
    display: none;
  }
  .hidden-sm {
    display: block;
  }
}
