.faq-green {
    color: #20E083;
}
.faq-black{
    color: #17191E;
}
.section-title{
    color: #080808;
}

.list-disc li{
    margin-left: 1rem !important;
}