.faq-title {
  color: #1a1a1a;
}
.faq-help {
  color: #3a3a3a;
}
.faq-question {
  color: #233143;
}
.faq-question1 {
  color: #233143;
}
.faq-item {
  /* border-top: 1px solid rgba(148, 148, 148, 0.50); */
  border-bottom: 1px solid rgba(148, 148, 148, 0.5);
}
.faq-answer {
  color: #233143;
}
.faq-answer1 {
  color: #474645;
  opacity: .7;
}
span a {
  font-weight: 700;
  cursor: pointer;
}
p span {
  text-decoration: underline;
}
p{
  display: inline;
}
.icon-wrapper {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
}
.open-icon {
  transform: rotate(45deg);
}
/* Small - Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
  .faq-wrapper {
    margin: 0%;
  }
}

/* Extra large devices (large laptops and desktops, 769px and up) */
@media only screen and (min-width: 769px) {
  .faq-wrapper {
    margin: 0 20%;
  }
}
