.footer-bg{
    background: #F9F9F9;    
}
footer ul {
    color: #000; 
}
.listed {
    color: #3A3A3A;
}
.separator{
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #DFE1E5;
}
.footer-help{
    color: #233143;
}
/* Small - Medium devices (landscape tablets, 768px and down) */
@media only screen and (max-width: 768px) {
    .hidden-sm {
      display: none;
    }
    .hidden-lg {
      display: block;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 769px and up) */
  @media only screen and (min-width: 769px) {
    .hidden-lg {
      display: none;
    }
    .hidden-sm {
      display: block;
    }
  }