.bg-img {
  position: absolute;
  right: 28px;
  top: 0px;
  width: 200px;
  height: auto;
}

.sect-title {
  color: #FFF;
}
.sect-sub{
color: #20E083;
}

.cust-grey-3 {
  color: #B6B6BD;
}